.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/*.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}*/

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  width: 750px;
}

.container ul li {
  width: 150px;
  height: 100px;
  float: left;
  list-style-type: none;
}

.container ul {
  display: inline;
}

.type-normal {
  background-color:#aa9
}
.type-fire {
  background-color:#f42
}
.type-water {
  background-color:#39f
}
.type-electric {
  background-color:#fc3
}
.type-grass {
  background-color:#7c5
}
.type-ice {
  background-color:#6cf
}
.type-fighting {
  background-color:#b54
}
.type-poison {
  background-color:#a59
}
.type-ground {
  background-color:#db5
}
.type-flying {
  background-color:#89f
}
.type-psychic {
  background-color:#f59
}
.type-bug {
  background-color:#ab2
}
.type-rock {
  background-color:#ba6
}
.type-ghost {
  background-color:#66b
}
.type-dragon {
  background-color:#76e
}
.type-dark {
  background-color:#754
}
.type-steel {
  background-color:#aab
}
.type-fairy {
  background-color:#e9e
}
.type-curse {
  background-color:#698
}
.type-icon {
  display:inline-block;
  width:66px;
  margin-bottom:4px;
  /*background:#dbdbdb;*/
  border:1px solid #a3a3a3;
  border-radius:4px;
  border:1px solid rgba(0,0,0,.2);
  color:#fff;
  font-size:.75rem;
  font-weight:normal;
  line-height:1.5rem;
  text-align:center;
  text-shadow:1px 1px 2px rgba(0,0,0,.7);
  text-transform:uppercase;
  transition:opacity .4s
}
.type-icon:hover,
.type-icon:active,
.type-icon:focus {
  color:#fff;
  text-decoration:none
}
.type-icon:link:hover,
.type-icon:link:active,
.type-icon:link:focus {
  opacity:.85
}
li {
  display:block !important;  
}
/*input {
  display: none;
}*/